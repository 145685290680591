<template>
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <customers></customers>
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import Customers from "@/components/app/customer/Customers";
    export default {
        name: 'Customers_view',
        data: function(){
            return {
                pageName: 'Customers',
                segments: [
                    { link: false, text: 'Customers' },
                ],
            }
        },
        props: {},
        components: {
            Customers,
            BreadCrumb,
        },
        computed: {

        },

    }
</script>

<style scoped>

</style>
